(function($) {
  var can_ajax, resize_delay,
    dont_load_page_after_popstate_change = false,
    old_url, site_url = MyAjax.ajaxurl.replace("wp-admin/admin-ajax.php", "");

  var ms_ie = false;
  var ua = window.navigator.userAgent;
  var old_ie = ua.indexOf('MSIE ');
  var new_ie = ua.indexOf('Trident/');

  if ((old_ie > -1) || (new_ie > -1)) {
    ms_ie = true;
  }
  if (Modernizr.history && ms_ie !== true) { // ask Modernizr if the browser supports history
    can_ajax = true;
  } else {
    can_ajax = false;
  }
  //////////////////////////////////////////////////

  //can_ajax = false;


  $(".generic_subpage_wrapper .container-fluid").children().each(function(i, e) {
    if (i % 2 === 0) {
      $(this).addClass("even_row");
    } else {
      $(this).addClass("odd_row");
    }
  });

  var second_row_image_height;

  second_row_image_height = $(".second_home_row .the_image img").height();
  $(".home_page_wrapper .second_home_row .left_border").height(second_row_image_height);

  $(window).resize(function() {
    second_row_image_height = $(".second_home_row .the_image img").height();
    $(".home_page_wrapper .second_home_row .left_border").height(second_row_image_height);

    homepage_fourth_row_image_height = $(".fourth_home_row .button_wrapper img").height();
    $(".home_page_wrapper .fourth_home_row .left_border").height(homepage_fourth_row_image_height);

    homepage_fourth_row_image_width = $(".fourth_home_row .button_wrapper img").width();
    $(".home_page_wrapper .fourth_home_row .top_border, .fourth_home_row .bottom_border").width(homepage_fourth_row_image_width);

    subpage_fourth_row_image_height = $(".generic_subpage_wrapper .button_wrapper img").height();
    $(".generic_subpage_wrapper .fourth_subpage_row .left_border").height(subpage_fourth_row_image_height);

    subpage_fourth_row_image_width = $(".generic_subpage_wrapper .button_wrapper img").width();
    $(".generic_subpage_wrapper .fourth_subpage_row .top_border, .generic_subpage_wrapper .bottom_border").width(subpage_fourth_row_image_width);
  });

  var subpage_other_image_width = $(".generic_subpage_wrapper .second_row_image img").width();
  $(".second_subpage_row .top_border").css("width", subpage_other_image_width);

  var subpage_other_image_height = $(".generic_subpage_wrapper .second_row_image img").height();
  $(".second_subpage_row .left_border").css("height", subpage_other_image_height);

  var about_us_image_width = $(".generic_subpage_wrapper .second_about_us_row img").width();
  $(".second_about_us_row .top_border").css("width", about_us_image_width);

  var about_us_image_height = $(".generic_subpage_wrapper .second_about_us_row img").height();
  $(".second_about_us_row .left_border").css("height", about_us_image_height);

  $('.mega_filler_image').prev().css("display", "none");

  $('.button_wrapper').hover(function() {
    TweenMax.to($(this).find("img"), 0.5, {
      scale: 1.1
    });
    TweenMax.to($(this).find(".transparent_overlay"), 0.5, {
      autoAlpha: 0.0
    });
  }, function() {
    TweenMax.to($(this).find("img"), 0.5, {
      scale: 1.0
    });
    TweenMax.to($(this).find(".transparent_overlay"), 0.5, {
      autoAlpha: 1.0
    });
  });

  // WooCommerce workarounds
  $(window).load(function() {
    // Single product pages
    $(".single-product h1.product_title.entry-title").prependTo(".entry-summary");
    $("<span class='quantity_label'>Quantity</span>").prependTo(".entry-summary .quantity");
    $(".single-product .woocommerce-breadcrumb").wrap("<div class='sp_breadcrumb_wrapper'></div>");
    // Category pages
    $(".woo_cat_page .header_wrapper").insertAfter(".woocommerce-breadcrumb");
    if (!$(".single-product .woocommerce-message").length) {

      // Work out how much margin-top to add to the product area...
      var top_value;
      if ($(window).width() > 991) {
        top_value = "120px";
      } else {
        top_value = "15px";
      }
      $(".single-product .product").css("margin-top", top_value);

      // ...and then do it on resize too
      $(window).resize(function() {
        if ($(window).width() > 991) {
          top_value = "120px";
        } else {
          top_value = "15px";
        }
        $(".single-product .product").css("margin-top", top_value);
      });
    }
    // Checkout page
    $("#order_review_heading").prependTo("#order_review");
  });

  $("<div class='top_progress_bar'><div class='container'><div class='row'><div class='col-xs-12 col-sm-12 col-md-12 col-lg-12'><span class='current progress_label'>Shopping Cart</span><img class='right_arrow_span' src='/wp-content/themes/sage-8.4.2/dist/images/right_arrow.png' alt='Right arrow'><span class='progress_label'>Checkout Details</span><img class='right_arrow_span' src='/wp-content/themes/sage-8.4.2/dist/images/right_arrow.png' alt='Right arrow'><span class='progress_label'>Order Complete</span></div></div></div></div>").insertAfter(".woocommerce-cart #the_page>.page_header");
  $("<div class='top_progress_bar'><div class='container'><div class='row'><div class='col-xs-12 col-sm-12 col-md-12 col-lg-12'><a class='progress_label' href='/basket/'>Shopping Cart</a><img class='right_arrow_span' src='/wp-content/themes/sage-8.4.2/dist/images/right_arrow.png' alt='Right arrow'><span class='current progress_label'>Checkout Details</span><img class='right_arrow_span' src='/wp-content/themes/sage-8.4.2/dist/images/right_arrow.png' alt='Right arrow'><span class='progress_label'>Order Complete</span></div></div></div></div>").insertAfter(".woocommerce-checkout #the_page>.page_header");

  $(window).load(function() {
    setTimeout(function() {
      $(".checkout_coupon").css("display", "none");
    }, 50);
  });

  ////////////////////
  var set_col_hights = function(el, extra) {
    if (typeof extra === "undefined") {
      extra = 0;
    }
    // Get an array of all element heights
    var elementHeights = $(el).map(function() {
      return $(this).outerHeight(true);
    }).get();
    //console.log(elementHeights);
    // Math.max takes a variable number of arguments
    // `apply` is equivalent to passing each height as an argument
    var maxHeight = Math.max.apply(null, elementHeights);

    // Set each height to the max height
    $(el).height(maxHeight + extra);
  };

  $(window).load(function() {
    /*                    if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(767) + 'px)')) {
                            set_col_hights('.feature_box .body');
                            set_col_hights('.home .output_review .comment');
                            set_col_hights('.blog_feed_heights');
                        }*/
  });
  ////////////////////





  ///////////////////////////////////////////////////////
  var pop_over_box,
    pop_over_box_width,
    document_width_for_pop_over = $(window).width(),
    pop_over_box_move = function() {
      TweenMax.to("#grey_page_cover", 0.5, { top: $(window).scrollTop() });
      $("body, html").css("overflow", "hidden");
    },
    do_popup_layout = function() {
      //pop_up_remove_me glyphicon glyphicon-remove
      if (!$("#grey_page_cover").length) {
        $("body").append('<div id="grey_page_cover"><div id="pop_over_box"><div class="pop_up_remove_me"></div><div class="popup_content_here"></div></div></div>');
        pop_over_box = $("#pop_over_box");
      }

      if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
        pop_over_box_width = "90%";
      } else {
        pop_over_box_width = "70%";
      }

      TweenMax.set($("#grey_page_cover"), {
        position: "absolute",
        top: "0",
        height: $(window).innerHeight() + "px",
        width: "100%",
        zIndex: "9",
        background: "rgba(0, 0, 0, 0.37)"
      });
      TweenMax.set($(".popup_content_here", pop_over_box), {
        height: "100%"
      });
      TweenMax.set($(".pop_up_remove_me", pop_over_box), {
        position: "absolute",
        backgroundImage: "url('" + site_url + "wp-content/themes/sage-8.4.2/dist/images/close_pop_up.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "12px 11px",
        width: "85px",
        height: "85px",
        right: "20px",
        top: "10px",
        cursor: "pointer",
        zIndex: 999,
        color: "#add138"
      });

      // Making a modal video responsive..

      var modal_width;

      if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(450) + 'px)')) {
        $(".youtube_iframe").css("height", "240px");
        modal_width = "370px";
      } else if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(600) + 'px)')) {
        $(".youtube_iframe").css("height", "275px");
        modal_width = "425px";
      } else if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(767) + 'px)')) {
        $(".youtube_iframe").css("height", "367px");
        modal_width = "567px";
      } else if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
        $(".youtube_iframe").css("height", "458px");
        modal_width = "708px";
      } else {
        $(".youtube_iframe").css("height", "550px");
        modal_width = "850px";
      }

      TweenMax.set(pop_over_box, { // background
        position: "absolute",
        textAlign: "left",
        left: "50%",
        top: "50%",
        height: "auto",
        width: modal_width,
        zIndex: 999,
        xPercent: -50,
        yPercent: -50
      });
    },
    set_up_pop_over_box = function() {
      $(window).on("scroll.popover_scroll", pop_over_box_move);
      $(window).on("resize.popover_resize", function() {
        if (document_width_for_pop_over !== $(window).width()) { // resize / scroll was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
          document_width_for_pop_over = $(window).width();
          set_up_pop_over_box();
        }
      });
      do_popup_layout();
      pop_over_box_move();
    },
    popup_set_html = function(the_popup_html) {
      $(".popup_content_here", pop_over_box).html(the_popup_html);
    },
    remove_the_popup = function() {
      $(window).off("scroll.popover_scroll");
      $(window).off("resize.popover_resize");
      $("body, html").css("overflow", "auto");
      $("#grey_page_cover").remove();
    };
  $(document).on("click", ".pop_up_remove_me, #grey_page_cover", function(e) {
    // stop child elements triggering it
    if (e.target !== e.currentTarget) { // http://stackoverflow.com/a/36876862/2129024
      return;
    }
    remove_the_popup();
  });
  // call with set_up_pop_over_box() 
  // and then pass html into the popup with popup_set_html(the_popup_html)
  // then use remove_the_popup() to close it;
  ///////////////////////////////////////////////////////

  // if site url contains...
  // call with set_up_pop_over_box() 

  // Open video on home page
  $("#click_for_top_video").on("click", function() {
    var video_id = $("#data_video").attr("data-video");
    var the_popup_html = '<iframe class="youtube_iframe" width="100%" height="100%" src="https://www.youtube.com/embed/' + video_id + '" frameborder="0" allowfullscreen></iframe>';
    set_up_pop_over_box();
    popup_set_html(the_popup_html);
    $(".youtube_iframe").css("height", "550px");
  });

  // Open videos on course/archive page
  $(".click_for_video").on("click", function() {
    var video_id = $(this).next().attr("data-video");
    var the_popup_html = '<iframe class="youtube_iframe" width="100%" height="100%" src="https://www.youtube.com/embed/' + video_id + '" frameborder="0" allowfullscreen></iframe>';
    set_up_pop_over_box();
    popup_set_html(the_popup_html);
    $(".youtube_iframe").css("height", "550px");
  });

  // Buy button modal
  $(".buy_button:not(.purchased)").on("click", function() {
    $(".product_popup").css("display", "none");
    var the_popup_html = $(this).closest(".single_product_block").find(".product_popup").clone();
    $(the_popup_html).css("display", "block");
    set_up_pop_over_box();
    popup_set_html(the_popup_html);
  });

  $(".course_content_button:not(.purchased)").on("click", function() {
    $(".course_content_popup").css("display", "none");
    var the_popup_html = $(this).closest(".single_product_block").find(".course_content_popup").clone();
    $(the_popup_html).css("display", "block");
    set_up_pop_over_box();
    popup_set_html(the_popup_html);
  });

  // Hide star wrapper on course/archive page
  $(".archive_star_rating").each(function() {
    if ($(this).find('.star-rating').length === 0) {
      $(this).css("display", "none");
    }
  });

  $(".hidden_content_wrapper:first").css("display", "block");

  $(".expand_button").on("click", function() {
    TweenMax.set(".expand_button>img", {
      rotation: "0"
    });
    TweenMax.set(this.children, {
      rotation: "45"
    });
    $(".expand_button span").css("display", "block");
    $(".hidden_content_wrapper").css("display", "none");
    $(this).find("span").css("display", "none");
    $(this).parent().parent().parent().next().css("display", "block");
    goToByScroll($(this).closest(".expandable_row"), 0, 1.0);
  });

  // Initialise landing slider - but only the first time round!

  var slides_to_show;

  if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
    slides_to_show = 3;
  } else if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(767) + 'px)')) {
    slides_to_show = 1;
  } else {
    slides_to_show = 5;
  }

  $(window).load(function() {
    if ($(".video_group_carousel_1.slick-initialized").length > 0) {} else {
      $(".video_group_carousel_1").slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: slides_to_show,
        centerMode: true,
        prevArrow: $("#previous_arrow_button_1"),
        nextArrow: $("#next_arrow_button_1"),
        autoplay: false,
        draggable: false
      });
      $(window).trigger('resize');
      $(".video_section_1").css("display", "block");
    }
  });

  $(".show_course_button").on("click", function() {
    $(window).trigger('resize');

    // Set active button colours
    $(".show_course_button").css("background", "#44C7F4");
    $(this).css("background", "#F6921E");

    // Get attributes based on which button is clicked
    current_course_attr = "." + $(this).attr("data-course");
    current_video_attr = "." + $(this).attr("data-video");
    carousel_attr = "." + $(this).attr("data-carousel");
    next_arrow_attr = "#" + $(this).attr("data-nextarrow");
    prev_arrow_attr = "#" + $(this).attr("data-prevarrow");

    // Hide any carousels on the page
    $(".video_section").css("display", "none");

    // Use button attribute to match up with the course class - but hide the rest first!
    $(".course_section").css("display", "none");
    $(current_course_attr).css("display", "block");

    // Has the slider been initialised already? If not, initialise it!
    if (($(carousel_attr + ".slick-initialized").length > 0) || (carousel_attr === ".video_group_carousel_1")) {
      // Do nothing
    } else {
      $(carousel_attr).slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: slides_to_show,
        centerMode: true,
        prevArrow: $(prev_arrow_attr),
        nextArrow: $(next_arrow_attr),
        autoplay: false,
        draggable: false
      });
    }
    $(window).trigger('resize');
    $(current_video_attr).css("display", "block");
  });

  //////////////////////////////////////////////

  /* jshint ignore:start */

  //Pure JS, completely customizable preloader from GreenSock.
  //Once you create an instance like var preloader = new GSPreloader(), call preloader.active(true) to open it, preloader.active(false) to close it, and preloader.active() to get the current status. Only requires TweenLite and CSSPlugin (http://www.greensock.com/gsap/)
  var preloader = new GSPreloader({
    radius: 40,
    dotSize: 20,
    dotCount: 10,
    colors: ["#bbb", "#E2E2E2"], //have as many or as few colors as you want.
    boxOpacity: 0.2,
    boxBorder: "1px solid #fff",
    animationOffset: 1.8, //jump 1.8 seconds into the animation for a more active part of the spinning initially (just looks a bit better in my opinion)
  });

  //open the preloader
  //preloader.active(true);

  //this is the whole preloader class/function
  function GSPreloader(options) {
    options = options || {};
    var parent = options.parent || document.body,
      element = this.element = document.createElement("div"),
      radius = options.radius || 42,
      dotSize = options.dotSize || 15,
      animationOffset = options.animationOffset || 1.8, //jumps to a more active part of the animation initially (just looks cooler especially when the preloader isn't displayed for very long)
      createDot = function(rotation) {
        var dot = document.createElement("div");
        element.appendChild(dot);
        TweenLite.set(dot, {
          width: dotSize,
          height: dotSize,
          transformOrigin: (-radius + "px 0px"),
          x: radius,
          backgroundColor: colors[colors.length - 1],
          borderRadius: "50%",
          force3D: true,
          position: "absolute",
          rotation: rotation
        });
        dot.className = options.dotClass || "preloader-dot";
        return dot;
      },
      i = options.dotCount || 10,
      rotationIncrement = 360 / i,
      colors = options.colors || ["#012b7f", "#4678db"],
      animation = new TimelineLite({
        paused: true
      }),
      dots = [],
      isActive = false,
      box = document.createElement("div"),
      tl, dot, closingAnimation, j;
    colors.push(colors.shift());

    //setup background box
    TweenLite.set(box, {
      width: radius * 2 + 70,
      height: radius * 2 + 70,
      borderRadius: "14px",
      //backgroundColor: options.boxColor || "white",
      //border: options.boxBorder || "1px solid #AAA",
      position: "absolute",
      xPercent: -50,
      yPercent: -50,
      opacity: ((options.boxOpacity != null) ? options.boxOpacity : 0.3)
    });
    box.className = options.boxClass || "preloader-box";
    element.appendChild(box);

    parent.appendChild(element);
    TweenLite.set(element, {
      position: "fixed",
      top: "45%",
      left: "50%",
      perspective: 600,
      overflow: "visible",
      zIndex: 2000
    });
    animation.from(box, 0.1, {
      opacity: 0,
      scale: 0.1,
      ease: Power1.easeOut
    }, animationOffset);
    while (--i > -1) {
      dot = createDot(i * rotationIncrement);
      dots.unshift(dot);
      animation.from(dot, 0.1, {
        scale: 0.01,
        opacity: 0,
        ease: Power1.easeOut
      }, animationOffset);
      //tuck the repeating parts of the animation into a nested TimelineMax (the intro shouldn't be repeated)
      tl = new TimelineMax({
        repeat: -1,
        repeatDelay: 0.25
      });
      for (j = 0; j < colors.length; j++) {
        tl.to(dot, 2.5, {
            rotation: "-=360",
            ease: Power2.easeInOut
          }, j * 2.9)
          .to(dot, 1.2, {
            skewX: "+=360",
            backgroundColor: colors[j],
            ease: Power2.easeInOut
          }, 1.6 + 2.9 * j);
      }
      //stagger its placement into the master timeline
      animation.add(tl, i * 0.07);
    }
    if (TweenLite.render) {
      TweenLite.render(); //trigger the from() tweens' lazy-rendering (otherwise it'd take one tick to render everything in the beginning state, thus things may flash on the screen for a moment initially). There are other ways around this, but TweenLite.render() is probably the simplest in this case.
    }

    //call preloader.active(true) to open the preloader, preloader.active(false) to close it, or preloader.active() to get the current state.
    this.active = function(show) {
      if (!arguments.length) {
        return isActive;
      }
      if (isActive != show) {
        isActive = show;
        if (closingAnimation) {
          closingAnimation.kill(); //in case the preloader is made active/inactive/active/inactive really fast and there's still a closing animation running, kill it.
        }
        if (isActive) {
          //on                                
          TweenLite.to(".surge_cover", 0.5, {
            autoAlpha: 0.70,
            zIndex: "10"
          });
          element.style.visibility = "visible";
          TweenLite.set([element, box], {
            rotation: 0
          });
          animation.play(animationOffset);
        } else {
          //off
          TweenLite.to(".surge_cover", 0.5, {
            autoAlpha: 0.0,
            zIndex: "-1"
          });
          closingAnimation = new TimelineLite();
          if (animation.time() < animationOffset + 0.3) {
            animation.pause();
            closingAnimation.to(element, 1, {
              rotation: -360,
              ease: Power1.easeInOut
            }).to(box, 1, {
              rotation: 360,
              ease: Power1.easeInOut
            }, 0);
          }
          closingAnimation.staggerTo(dots, 0.3, {
            scale: 0.01,
            opacity: 0,
            ease: Power1.easeIn,
            overwrite: false
          }, 0.05, 0).to(box, 0.4, {
            opacity: 0,
            scale: 0.2,
            ease: Power2.easeIn,
            overwrite: false
          }, 0).call(function() {
            animation.pause();
            closingAnimation = null;
          }).set(element, {
            visibility: "hidden"
          });
        }
      }
      return this;
    };
  }

  /* jshint ignore:end */

  if ($("body").hasClass("updated_dom")) {
    // HERE we can smoothly remove the preloader / 
    preloader.active(true);
    setTimeout(function() {
      preloader.active(false);
      setTimeout(function() {
        TweenLite.set(".surge_cover", {
          autoAlpha: 0.0,
          zIndex: "-1"
        });
        //console.log("remove cover");
      }, 600);
    }, 300);
    //console.log("updated_dom");
  }

  var scroll_animation;

  function goToByScroll(location, offset_top, speed) {
    if (typeof scroll_animation !== "undefined") {
      scroll_animation.kill();
    }
    scroll_animation = new TimelineMax();
    scroll_animation.to($(window), speed, {
      scrollTo: {
        y: location,
        offsetY: offset_top,
        autoKill: true
      },
      ease: Power3.easeOut
    });
  }


  // remove link on logo if its the home page                     
  if (window.location.href === site_url) {
    $("a.logo_link").attr("href", "#");
  } else {
    $("a.logo_link").attr("href", site_url);
  }

  // make these images responsive:
  // blog post page images
  $(".entry-content img").addClass("img-responsive");



  // make plain text email address less likely to be spamed.
  $(".less_spam_email").each(function() {
    var ats, dots, address, i;
    ats = [' at ', ' (at) ', ' [at] '];
    dots = [' dot ', ' (dot) ', ' [dot] ', ' [dot] '];
    address = $(this).html();
    for (i = 0; i < ats.length; i++) {
      address = address.replace(ats[i], '@');
    }
    for (i = 0; i < dots.length; i++) {
      address = address.replace(dots[i], '.');
    }
    $(this).html('<a target="_blank" href="mailto:' + address + '">' + address + '</a>');
  });




  //start of code to make the contact page map work...

  /*
   *  add_marker
   *
   *  This function will add a marker to the selected Google Map
   *
   *  @type    function
   *  @date    8/11/2013
   *  @since   4.3.0
   *
   *  @param   $marker (jQuery element)
   *  @param   map (Google Map object)
   *  @return  n/a
   */
  function add_marker($marker, map) {

    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open(map, marker);

      });
    }

  }
  /*
   *  center_map
   *
   *  This function will center the map, showing all markers attached to this map
   *
   *  @type    function
   *  @date    8/11/2013
   *  @since   4.3.0
   *
   *  @param   map (Google Map object)
   *  @return  n/a
   */

  function center_map(map) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {

      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);

    });

    // only 1 marker?
    if (map.markers.length === 1) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(10);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }

  }
  /*
   *  render_map
   *
   *  This function will render a Google Map onto the selected jQuery element
   *
   *  @type    function
   *  @date    8/11/2013
   *  @since   4.3.0
   *
   *  @param   $el (jQuery element)
   *  @return  n/a
   */

  function render_map($el) {

    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom: 10,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    // create map               
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function() {

      add_marker($(this), map);

    });

    // center map
    center_map(map);

  }

  if (window.location.pathname === "/contact/") {

    /*
     *  document ready
     *
     *  This function will render each map when the document is ready (page has loaded)
     *
     *  @type    function
     *  @date    8/11/2013
     *  @since   5.0.0
     *
     *  @param   n/a
     *  @return  n/a
     */

    $(document).ready(function() {
      $('.acf-map').each(function() {
        render_map($(this));
      });
    });
  }
  // end contact page google map js
  //////////////////////////////

  var xhr, current_url;

  if (can_ajax || ms_ie) {
    var $main = $("#ajax_me_wrapper"),
      loadPage = function(href, type) {
        current_url = window.location.href;
        if (xhr && xhr.readystate !== 4) {
          xhr.abort(); // if user clicked twice on a link abort the first
        }
        xhr = $.ajax({
          type: 'GET',
          url: href,
          data: {
            //MY_REFERER: old_url, NEW_REFERER: href 
          },
          /*                                beforeSend: function(xhr) {
                                              xhr.setRequestHeader("MY_REFERER", old_url);
                                          },*/
          success: function(response) {
            var split_html = response.split('<body class="');
            var updated_html = split_html[0] + '<body class="' + "updated_dom " + split_html[1];
            if (type !== "popstate") {
              goToByScroll($("#the_page", document), 0, 1.0);
              /* jshint ignore:start */
              setTimeout(function() {
                document.open();
                document.write(updated_html);
                document.close();
              }, 1000);
              /* jshint ignore:end */
            } else {
              /* jshint ignore:start */
              document.open();
              document.write(updated_html);
              document.close();
              /* jshint ignore:end */
            }
          },
          error: function(xhr) {
            if (xhr.statusText !== "abort") { // user just clicked the link twice and we have handled this so its not an error.
              preloader.active(false);
              $("body").css("cursor", "auto");
              //console.log(current_url);
              history.replaceState({}, '', current_url);
              swal({
                title: "Warning",
                text: "Sorry but there was an error: " + xhr.status + " " + xhr.statusText,
                showConfirmButton: false,
                type: "warning",
                timer: 3800
              });
            }
          }
        });
      };


    /*
     * Necessary hack because WebKit fires a popstate event on document load
     * https://code.google.com/p/chromium/issues/detail?id=63040
     * https://bugs.webkit.org/process_bug.cgi
     */
    window.addEventListener('load', function() {
      setTimeout(function() {
        window.addEventListener('popstate', function() {
          /*console.log(window.location);
          console.log(current_url);*/
          if (window.location.href !== current_url) { //if the popstate puts the href back to the original pages url then do nothing.
            preloader.active(true);
            loadPage(location.href, "popstate");
          }
        });
      }, 0);
    });
    var start_navigation_away = function(el, url) {
      if (url !== "") {
        if (window.location.href.indexOf(".dev:3000") > -1 && url.indexOf(":3000") === -1) {
          // if we are on local dev pc add :3000 if its not already part of the url                                     
          url = url.replace(document.location.hostname, document.location.hostname + ":3000");
        }
        preloader.active(true);
        $("body").css("cursor", "progress");
        //console.log(site_url);
        if (url.indexOf(site_url) > -1 || url.indexOf(':') === -1) {
          if (ms_ie) {
            window.location.href = url;
          } else {
            loadPage(url);
            //history.state = "navigate";
            history.pushState({}, '', url);
          }
          return false;
        }
      } else {
        var href = $(el).attr("href");
        //console.log(href);
        //if (href !== "#") {
        if (!href.match("^#")) { // if href does not start with hash                            
          if (window.location.href.indexOf(".dev:3000") > -1 && href.indexOf(":3000") === -1) {
            // if we are on local dev pc and current url has :3000 add :3000 if its not already part of the links url                                     
            href = href.replace(document.location.hostname, document.location.hostname + ":3000");
          }
          if (href.indexOf(site_url) === 0 || href.indexOf(':') === -1) {
            preloader.active(true);
            $("body").css("cursor", "progress");
            if (ms_ie) {
              window.location.href = href;
            } else {
              loadPage(href);
              //history.state = "navigate";
              history.pushState({}, '', href);
            }
          } else {
            window.open(href, '_blank');
            preloader.active(false);
            return false;
          }
        }
      }
    };
    $(document).on("click", "a, area", function() {
      if (!$(this).closest("#wpadminbar").length) {
        if (this.href === window.location.href) {
          /*                    swal({
                                  title: "That link leads to the same page as you're already on!",
                                  type: "info",
                                  timer: 3000,
                                  showConfirmButton: false
                              });*/
          return false;
        }
        if (!$(this).hasClass("festi-cart-remove-product") && !$(this).hasClass("remove") && !$(this).hasClass("pdf_link") && !$(this).hasClass("ajax_add_to_cart") && !$(this).parent().hasClass("gallery-icon")) {
          old_url = window.location.href;
          start_navigation_away(this, "");
          return false;
        }

      }
    });
  }

  /* woo mini cart */
  var number_of_items_in_cart, tl, mini_cart_height = $('.widget_shopping_cart').outerHeight(true);
  //console.log("mini_cart_height: "+mini_cart_height);
  function SURGE_hover_mini_cart() {
    if ($(window).width() > 767) {
      $('.basket_area').hover(function() {
        if (typeof tl !== "undefined") {
          tl.kill();
        }
        tl = new TimelineMax({ delay: 0.0 });
        tl.set(".widget_shopping_cart", {
            autoAlpha: 0.0,
            height: "0px"
          })
          .to(".widget_shopping_cart", 1.1, {
            autoAlpha: 1.0,
            height: mini_cart_height + "px",
            zIndex: "6",
            ease: Expo.easeOut,
            y: 0
          });
      }, function() {
        if (typeof tl !== "undefined") {
          tl.kill();
        }
        tl = new TimelineMax({ delay: 0.0 });
        tl.to(".widget_shopping_cart", 1.1, {
          autoAlpha: 0.0,
          height: "0px",
          zIndex: "-1"
        });
      });
    } else {
      $(document).on("click", ".basket_area", function() {
        start_navigation_away("", $(".widget_shopping_cart .button.wc-forward").attr("href"));
      });
    }
  }

  // add dots around titles on cart page H and A
  $(".woocommerce-cart .push_page_down h1").html('<div class="header_wrapper"> <h1><span class="left_dot"></span>Your Basket<span class="right_dot"></span></h1> </div>');
  $(".woocommerce-cart .calculated_shipping h2").html('<div class="header_wrapper"> <h1><span class="left_dot"></span>Basket Totals<span class="right_dot"></span></h1> </div>');

  $(window).load(function() {
    $(".woocommerce form").fadeIn();
  });
  $(".quantity").insertBefore(".variations");

  if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(767) + 'px)')) {
    $.each($(".woocommerce-cart .product-remove"), function() {
      $(this).insertAfter($(this).closest("tr").find(".product-subtotal"));
    });
  }
  $(".woocommerce-cart .quantity input").on('change', function() {
    if (!$(this).closest(".product-quantity").find('input[name="update_cart"]').length) {
      $(this).closest(".quantity").after('<input type="submit" class="button" name="update_cart" value="Update Basket">');
    }
  });

  $(document).on("wc_fragments_refreshed", function() {
    $(".woocommerce-cart .quantity input").on('change', function() {
      if (!$(this).closest(".product-quantity").find('input[name="update_cart"]').length) {
        $(this).closest(".quantity").after('<input type="submit" class="button" name="update_cart" value="Update Basket">');
      }
    });
    $(".woocommerce form").fadeIn(); // show cart items on cart page h and a
    if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(767) + 'px)')) {
      $.each($(".woocommerce-cart .product-remove"), function() {
        $(this).insertAfter($(this).closest("tr").find(".product-subtotal"));
      });
    }
    // this only runs when the cart is updated...
    if (typeof BJLL !== "undefined") {
      BJLL.check(); // BJ lazy load start showing images
    }
    number_of_items_in_cart = $(".widget_shopping_cart .mini_cart_item").length;
    if (number_of_items_in_cart) {
      mini_cart_height = $('.widget_shopping_cart').outerHeight(true);
      new SURGE_hover_mini_cart();
      $(".basket_area").append("<span class='number_of_items_in_cart'>" + number_of_items_in_cart + "</span>");
    }
  });

  // this will run every time the page is loaded but the if statment will return false if the cart was updated and will then not run but the above on function will take over.                
  number_of_items_in_cart = $(".widget_shopping_cart .mini_cart_item").length;
  if (number_of_items_in_cart) {
    $(".basket_area").append("<span class='number_of_items_in_cart'>" + number_of_items_in_cart + "</span>");
  }
  if (mini_cart_height > 50) {
    new SURGE_hover_mini_cart();
  }
  /* END woo mini cart */


  /*
  Search bar expand on click only when on mobile
  */
  $("#yith-searchsubmit").attr("value", ""); // remove the text that goes over the top of the search button
  $(".search-navigation [placeholder='Search for products']").attr("placeholder", "Search Product Range");

  //                 $(document).on("click", ".yith_woocommerce_ajax_search, #yith-searchsubmit", function() {
  //                     if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
  //                         swal({
  //                             title: "Search",
  //                             text: "Please type your search query:",
  //                             type: "input",
  //                             showCancelButton: true,
  //                             closeOnConfirm: false,
  //                             showLoaderOnConfirm: true,
  //                             animation: "slide-from-top",
  //                             inputPlaceholder: "",
  //                             allowEscapeKey: true
  //                         }, function(inputValue) {
  //                             if (inputValue === false) {
  //                                 return false;
  //                             }
  //                             if (inputValue === "") {
  //                                 swal.showInputError("Please type your search query into the field above.");
  //                                 return false;
  //                             }
  //                             start_navigation_away("", document.location.origin + "/?post_type=any&s=" + inputValue.replace(/ /g, "+"));
  //                         });
  //                     }
  //                 });

  /*
  End search bar expand on click only when on mobile
  */


  // Input type number - scroller arrows for cart


  $(".woocommerce-cart .cart_item").each(function() {

    $(this).find(".product-quantity").find(".quantity").append("<div class='top_arrow'></div><div class='bottom_arrow'></div>");

    $(this).find(".product-quantity").find(".quantity").find(".top_arrow").click(function() {
      var current_quantity = parseInt($(this).prev().attr("value"));
      $(this).prev().attr("value", parseInt(current_quantity) + 1);
    });

    $(this).find(".product-quantity").find(".quantity").find(".bottom_arrow").click(function() {
      var current_quantity = parseInt($(this).prev().prev().attr("value"));
      if (current_quantity <= 1) {
        $(this).prev().prev().attr("value", 1);
      } else {
        $(this).prev().prev().attr("value", parseInt(current_quantity) - 1);
      }
    });
  });

  $('.woocommerce-cart input[name="update_cart"]').removeAttr("disabled");

  $(".woocommerce-cart #coupon_code").attr("placeholder", "Voucher code");

  $(".woocommerce-cart .checkout-button").replaceWith('<a href="/checkout/" class="checkout-button button alt wc-forward"><span>Proceed to Checkout</span></a>');

  $(".product_controls .edit").click(function() {
    var swal_this = this;
    swal({
      title: "Are you sure?",
      text: "This will remove the product from your basket and take you back to the product page.",
      type: "warning",
      showCancelButton: true,
      closeOnConfirm: false,
      showLoaderOnConfirm: true,
      animation: "slide-from-top",
      inputPlaceholder: "",
      allowEscapeKey: true
    }, function() {
      $(swal_this).parent().parent().prev().prev().find(".remove").trigger("click");
      var that = swal_this;
      $(document).on("wc_fragments_refreshed", function() {
        var href = $(that).attr("data-href");
        start_navigation_away("", href);
      });
    });
  });

  $(document).on("click", ".cart_item span.remove", function() {
    var swal_this = this;
    swal({
      title: "Are you sure?",
      text: "This will remove the product from your basket.",
      type: "warning",
      showCancelButton: true,
      closeOnConfirm: true,
      showLoaderOnConfirm: true,
      animation: "slide-from-top",
      inputPlaceholder: "",
      allowEscapeKey: true
    }, function() {
      $(swal_this).closest(".cart_item").find(".product-remove a.remove").trigger("click");
    });
  });

  /* Mega menu */
  var mega,
    mega_open = function(mega_this) {
      if (!$(mega_this).is(".open")) {
        if (typeof mega !== "undefined") {
          mega.kill();
        }
        var mega_menu_height = $(".mega_dropdown", mega_this).outerHeight(true);
        mega = new TimelineMax({ delay: 0.0 });
        mega.set($("span.parent_text", mega_this), {
            backgroundColor: "#333132"
          }, 0)
          .set($("> a, > span", mega_this), {
            color: "#fff"
          }, 0);
        if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
          mega.set($(".mega_dropdown", mega_this), {
            height: "0px",
            autoAlpha: 0.0
          }, 0);
        } else {
          mega.set($(".mega_dropdown", mega_this), {
              height: "0px",
              autoAlpha: 0.0,
              position: "relative",
              top: "0"
            }, 0)
            .set($(mega_this).prev(".close_menu"), {
              y: 125
            }, 0)
            .set($(mega_this).closest(".SURGE_mega_menu"), {
              height: "inherit"
            }, 0)
            .to($(mega_this).prev(".close_menu"), 0.5, {
              y: 21,
              autoAlpha: 1.0,
              display: "block",
              rotation: 180
            }, 0);
        }

        mega.to($(".mega_dropdown", mega_this), 0.5, {
          zIndex: "9",
          height: mega_menu_height,
          autoAlpha: 1.0,
          ease: Expo.easeOut
        }, 0);
        $(mega_this).addClass("open");
      }
    },
    mega_close = function(mega_this) {
      $(mega_this).removeClass("open");
      if (typeof mega !== "undefined") {
        mega.kill();
      }
      mega = new TimelineMax({ delay: 0.0 });
      mega.set($.merge($("span.parent_text, > a, > span, .mega_dropdown", mega_this), $(mega_this).prev(".close_menu")), { clearProps: "all" });
    };


  var count_this = 0,
    mega_this,
    mobile_menu_height = $("#SURGE_mega_menu_1").outerHeight(true),
    document_width;

  $(document).ready(function() {
    document_width = $(window).width();
  });

  $(".slide_call_to_action a").on("click", function() {
    goToByScroll($(".product_category_blocks_wrapper"), 0, 1.0);
    return false;
  });

  $(".control_button").first().css("background", "#F6921E");
  $(".category_banner_wrapper").first().css("background", "#e6f6fc");

  $(".control_button").on("click", function() {
    $(".control_button").css("background", "#44C7F4");
    $(this).css("background", "#F6921E");
    $(".category_banner_wrapper").css("background", "#FFF");
    var category_destination = "." + $(this).attr('data-cat');
    if ($(category_destination).height() > 0) {
      goToByScroll($(category_destination), 0, 1.0);
      $(category_destination).css("background", "#e6f6fc");
    }
  });

  $(".category_banner_wrapper").each(function() {
    if ($(this).height() > 0) {
      $(this).css("padding-bottom", "30px");
    }
  });

  var mega_events = function() {
    if (document_width !== $(window).width()) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening
      document_width = $(window).width();
      TweenMax.set($("#SURGE_mega_menu_1"), { clearProps: "height" });
      mobile_menu_height = $("#SURGE_mega_menu_1").outerHeight(true);
      //alert("in mega_events");
      mega_close(".is_dropdown");
      //if (!navigator.userAgent.match(/Mobi/)) {
      if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
        $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once                           
        $('.is_dropdown').unbind("mouseenter");
        $('.is_dropdown').unbind("mouseleave");
        $('.is_dropdown')
          .mouseenter(function() {
            mega_this = this;
            //if (count_this === 0) {
            mega_open(mega_this);
            //}
            //count_this++;
            //alert("mouseenter");
            if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(1199) + 'px)')) {
              // code that will put the dropdown roughly below its hovered parent li
              var position = $(mega_this).position();
              var rightOffset = $(mega_this).closest(".SURGE_mega_menu").width() - (position.left + $(mega_this).width());
              //console.log("rightOffset: "+rightOffset);
              //console.log("position.left: "+position.left);
              var difference = (position.left - rightOffset) + ($(mega_this).width() / 2);

              difference = Math.abs(difference); // removing negative -
              //console.log("difference: "+difference);
              if (difference < 150) {
                $(".mega_dropdown", mega_this).css({ left: "17%", right: "17%" });
              } else if (position.left > rightOffset) {
                $(".mega_dropdown", mega_this).css({ left: "28%", right: "2.3%" });
              }
              //console.log("menu width: "+ $(".mega_dropdown", this).width());
            }
          })
          .mouseleave(function() {
            //alert("mouseleave");
            mega_this = this;
            mega_close(mega_this);
          });
      } else {
        $('.is_dropdown').unbind("mouseenter");
        $('.is_dropdown').unbind("mouseleave");
        $('.is_dropdown').off("click.mega_events"); // resets if this runns more than once
        $('.is_dropdown .parent_text').on("click.mega_events", function() {
          //alert("click");
          mega_close(".is_dropdown");
          mega_this = $(this).closest(".is_dropdown");
          if (!$(mega_this).is(".open")) {
            mega_open(mega_this);
            goToByScroll(mega_this, 40, 0.8);
          }
        });
      }
    }
  };
  mega_events();
  $(window).on("resize", mega_events);

  var mobile_menu;
  $('.close_menu, .navbar-toggle').on("click", function() {
    mega_close($(".is_dropdown"));
    if ($(this).is(".navbar-toggle")) {
      if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
        if ($(this).hasClass("collapsed")) {
          if (typeof mobile_menu !== "undefined") {
            mobile_menu.kill();
          }
          mobile_menu = new TimelineMax({ delay: 0.0 });
          mobile_menu.set($("#SURGE_mega_menu_1"), {
              height: "0",
              margin: "-23px 0",
              position: "relative"
            }, 0)
            .to($("#SURGE_mega_menu_1"), 1.0, {
              height: mobile_menu_height,
              margin: "0",
              autoAlpha: 1.0,
              ease: Expo.easeOut
            });
          $(this).removeClass("collapsed");
        } else {
          if (typeof mobile_menu !== "undefined") {
            mobile_menu.kill();
          }
          mobile_menu = new TimelineMax({ delay: 0.0 });
          mobile_menu.to($("#SURGE_mega_menu_1"), 1.0, {
            height: "0",
            margin: "-23px 0",
            autoAlpha: 0.0,
            ease: Expo.easeOut
          });
          $(this).addClass("collapsed");
        }
      }
    }
    if ($(this).is(".close_menu")) {
      goToByScroll("html", 0, 0.8);
    }
  });


  $(window).load(function() { // wait for images to load
    // set cols the same height
    var highest = null,
      hi = 0,
      collection = $(),
      process_collection = false,
      remove_hrs = true;
    if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
      $(".mega_col").each(function() {
        if ($(this).next().is("hr")) {
          collection = $.merge(collection, $(this));
          $(this).css({ "padding-right": "0", "background": "none" });
          process_collection = true;
        } else {
          collection = $.merge(collection, $(this));
        }
        if (process_collection) {
          $(collection).each(function(i, el) {
            if ($("> *", this).first().is("h4")) {
              remove_hrs = false;
            }
            ////
            var h = $(this).outerHeight(true);
            if (h > hi) {
              hi = h;
            }
          });
          if (remove_hrs) {
            $("> hr:first-of-type", collection).remove();
            hi = hi - 25;
          }
          $(collection).height(hi);
          // resets
          collection = $();
          process_collection = false;
          remove_hrs = true;
          hi = 0;
        }
      });
    } else {
      $(".mega_dropdown > hr").remove();
    }
    // remove end hr(s)
    //$(".mega_col hr").last().remove();
    $(".mega_dropdown hr:last-child").remove();
  });

  // set top level label wrappers to the size of the text within them so that they dont jiggle about on hover (when the text goes bold it will make the text bigger)
  // this will stop the text from pushing on the next one
  //                 if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
  //                     $(".SURGE_mega_menu .parent_li").each(function() {
  //                         $(this).width($(this).width());
  //                         $(".parent_text", this).attr("data-original_width", $(".parent_text", this).width());
  //                     });
  //                     $('.parent_li') // here we are takeing the increase of width from the padding on the text element to stop it overflowing its parent
  //                         .mouseenter(function() {
  //                             var current_side_padding = $(".parent_text", this).css("padding-left").replace("px", ""), // will be the same as padding right...
  //                                 hover_parent_text_width = $(".parent_text", this).width(),
  //                                 origin_width = $(".parent_text", this).attr("data-original_width"),
  //                                 added_with = hover_parent_text_width - origin_width,
  //                                 added_with_divided = added_with / 2;
  //                             new_side_padding = current_side_padding - added_with_divided;
  //                             $(".parent_text", this).css({ "padding-left": new_side_padding + "px", "padding-right": new_side_padding + "px" });
  //                         })
  //                         .mouseleave(function() {
  //                             TweenMax.set($(".parent_text", this), { clearProps: "all" });
  //                         });
  //                 }
  /* END mega menu */


  /* cookie policy */

  $(document).ready(function() {
    if ($.cookie("acceptsCookies") === "yes") {
      $(".exitMunchMyCookies").closest("#munchMyCookies").hide();
    } else {
      $(".exitMunchMyCookies").click(function() {
        $.cookie("acceptsCookies", "yes", { path: '/' });
        $(this).closest("#munchMyCookies").fadeOut(900);
      });
    }
  });
  // document ready
  /* END cookie policy */


  /* GSAP image slider homepage */
  var SURGE_GSAP_image_slider = function(setting) {
    var slider = $(setting.slider);
    if (slider.length) {
      var tl = setting.timeline,
        slides_in_group = setting.slides_in_group,
        slider_inner = $(".SURGE_gsap_inner", slider),
        slider_slides = $("> div", slider_inner),
        slide_buttons = $(".SURGE_slide_buttons", slider),
        switch_buttons = $(".slide_switch_buttons", slider),
        number_of_slides_minus_one = slider_slides.length - 1,
        number_of_slides = slider_slides.length,
        show_duration = setting.show_duration,
        animation_duration = setting.animation_duration,
        do_each_loop = true,
        pause_on_hover = setting.pause_on_hover,
        x = 0,
        switches = "";

      TweenLite.set(slider_inner, { x: "+=0" });
      $.each(setting.slides_in_group.min_width, function(key, val) {
        if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(parseInt(key, 10)) + 'px)')) {
          slides_in_group = setting.slides_in_group.min_width[key];
          //console.log("found in min_width: "+slides_in_group);   
          return false;
        }
      });
      $.each(setting.slides_in_group.max_width, function(key, val) {
        if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(parseInt(key, 10)) + 'px)')) {
          slides_in_group = setting.slides_in_group.max_width[key];
          //console.log("found in max_width: "+slides_in_group);    
          return false;
        }
      });
      var number_of_times_to_do_slide_animation = Math.floor(number_of_slides / slides_in_group);
      $(slider_slides).css("width", 100 / slides_in_group + "%");
      /*if (setting.type === "main slider") {
          theSplitText = new SplitText($(".text_box_text_body p", slider).not(".slide_call_to_action p"), { type: "words, chars" });
          TweenLite.set($(".text_box_text_body p", slider).not(".slide_call_to_action p"), { perspective: 400, autoAlpha: 1.0 });
          var split_count = $(".text_box_text_body p div").length;
      }*/
      if (setting.animation === "slide") {
        if (number_of_slides % slides_in_group === 0) {
          number_of_times_to_do_slide_animation--;
        }
      } else {
        number_of_times_to_do_slide_animation--;
      }
      for (var i = number_of_times_to_do_slide_animation; i >= 0; i--) {
        switches = switches + "<div></div>";
      }
      /*                        console.log("clear");
                              console.log("number_of_slides: " + number_of_slides);
                              console.log("slides_in_group: " + slides_in_group);
                              console.log(number_of_slides % slides_in_group);*/
      var do_last_slide;
      if (setting.type === "grouped slider" && setting.animation === "fade" && number_of_slides % slides_in_group !== 0) {
        // if using fade with grouped
        //number_of_slides = number_of_slides + 1;
        switches = switches + "<div></div>";
        do_last_slide = true;
        //number_of_times_to_do_slide_animation = number_of_times_to_do_slide_animation + 1;
      } else {
        do_last_slide = false;
      }




      $("> div:nth-child(" + slides_in_group + "n)", slider_inner).css("background-image", "none");
      $("> div", slider_inner).last().css("background-image", "none");

      $(".slide_switch_buttons", slider).html(switches);
      var slide_switch_buttons = $(slider).find(".slide_switch_buttons div");
      $.each(slider_slides, function(i, e) {
        if (typeof slides_in_group !== "undefined") {
          if (i % slides_in_group === 1 || slides_in_group === 1) {
            do_each_loop = true;
          } else {
            do_each_loop = false;
          }
          if (setting.animation === "fade" && Math.floor((number_of_slides - 1) / slides_in_group) === x && do_last_slide) {
            // if using fade with grouped - go ahead and do the last slide if we have a remainder
            do_each_loop = true;
          }
        }
        if (do_each_loop) {
          x++;
          var switch_index = x - 1;
          //if (setting.animation === "fade") {
          tl
            .add("start" + x)
            .set(slide_switch_buttons, {
              background: "#ffffff"
            }, "start" + x)
            .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
              background: "#E6BE8A"
            }, "start" + x);
          //}
          if (setting.animation === "fade") {
            tl
              //.add("start" + x)
              .set(slider_inner, {
                autoAlpha: 0.2
              }, "start" + x)
              .to(slider_inner, animation_duration, {
                autoAlpha: 1.0
              }, "start" + x);
          }


          // animations that happen after the full slide has faded in. e.g text animations
          /*                    if (setting.type === "main slider") {
                                  tl
                                      .add("start_inner_animation" + x)
                                      .set($(".text_box_text_body", this), {
                                          autoAlpha: 0.0,
                                          y: "-=40"
                                      }, "start" + x) // this is purposely set to the last label so that it is hidden before the full slide comes in
                                      .to($(".text_box_text_body", this), animation_duration, {
                                          autoAlpha: 1.0,
                                          y: "+=40",
                                          ease: Power3.easeOut
                                      }, "start_inner_animation" + x)
                                      .staggerFrom($(".text_box_text_body p div div", this), 1.3, {
                                          rotationX: 0,
                                          x: -250,
                                          transformOrigin: "0% 50% -50",
                                          scale: 9,
                                          autoAlpha: 0.0,
                                          ease: Back.easeOut
                                      }, 0.01, "start_inner_animation" + x + "+=" + animation_duration);
                              }*/


          // a label to fill the gap so we can understand what's happening when using currentLabel() later on in the hover function
          tl
            .add("not_animating_just_showing_slide" + x)
            .set({}, {}, "not_animating_just_showing_slide" + x + "+=" + show_duration);


          // start removing slide after the amount of seconds stored in show_duration (from last animation)

          if (setting.animation === "fade") {
            tl
              .add("start_removing_slide" + x)
              .to(slider_inner, animation_duration, {
                autoAlpha: 0.2
              }, "start_removing_slide" + x)
              .set(slider_inner, {
                x: "-=100%"
              }, "start_removing_slide" + x + "+=" + animation_duration);

          }
          if (setting.animation === "slide" && x <= number_of_times_to_do_slide_animation) {
            tl
              .set($(slide_switch_buttons).eq(switch_index), {
                background: "#E6BE8A"
              }, "start_removing_slide" + x)
              .set(slide_switch_buttons, {
                background: "#ffffff"
              }, "start_removing_slide" + x)
              .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
                background: "#E6BE8A"
              }, "start_removing_slide" + x)

              .add("start_removing_slide" + x)
              .to(slider_inner, animation_duration, {
                x: "-=100%"
              }, "start_removing_slide" + x);
          }
          if (setting.animation === "slide" && x === number_of_times_to_do_slide_animation) {
            switch_index = switch_index + 1;
            tl
              .set($(slide_switch_buttons).eq(switch_index), {
                background: "#E6BE8A"
              }, "start_removing_slide" + x)
              .set(slide_switch_buttons, {
                background: "#ffffff"
              }, "start_removing_slide" + x)
              .to($(slide_switch_buttons).eq(switch_index), animation_duration, {
                background: "#E6BE8A"
              }, "start_removing_slide" + x);
          }

        }
      });
      //console.log("x: " + x);
      var hover_slider,
        all_slide_btns = $.merge(slide_buttons, switch_buttons),
        current_label,
        intervaHandle;

      $(slider).hover(function() {
        if (setting.pause_on_hover) {
          if (intervaHandle) {
            clearInterval(intervaHandle);
            intervaHandle = null;
          }
          intervaHandle = setInterval(function() {
            current_label = tl.currentLabel();
            //console.log(current_label);
            if (current_label.indexOf("not_animating_just_showing_slide") >= 0) {
              clearInterval(intervaHandle);
              intervaHandle = null;
              tl.pause();
            }
          }, 200);
        }
        if (typeof hover_slider !== "undefined") {
          hover_slider.kill();
        }
        hover_slider = new TimelineMax();
        hover_slider.to(all_slide_btns, 1.0, {
          autoAlpha: 1.0,
          zIndex: 2,
          ease: Power3.easeOut
        });
      }, function() {
        if (intervaHandle) {
          clearInterval(intervaHandle);
          intervaHandle = null;
        }
        tl.play();
        if (typeof hover_slider !== "undefined") {
          hover_slider.kill();
        }
        hover_slider = new TimelineMax();
        hover_slider.to(all_slide_btns, 1.0, {
          autoAlpha: 0.0,
          zIndex: -1,
          ease: Power3.easeOut
        }).set(all_slide_btns, { zIndex: -1 });
      });

      $(".slide_switch_buttons div, .SURGE_slide_buttons", slider).on("click", function() {
        var to_time, current_label = tl.currentLabel(),
          time, current_label_number = parseInt(current_label.match(/\d+/), 10),
          purpusly_undefined; // http://stackoverflow.com/a/9282974/2129024;
        // no way other than checking the transform percentage directly

        if (intervaHandle) {
          clearInterval(intervaHandle);
          intervaHandle = null;
        }
        //console.log(current_label);
        if (current_label.indexOf("start_inner_animation") >= 0) {
          //only stop if there are active animations.
          tl.pause();
          time = tl.time();
        }
        if ($(this).is(".slide_switch_buttons div")) {
          var index = $(this).index();
          index = index + 1;
          to_time = tl.getLabelTime("not_animating_just_showing_slide" + index);
          if (setting.animation === "slide" && $(this).closest(".slide_switch_buttons").find("div").length === index) {
            to_time = tl.endTime();
          }
          TweenMax.to(tl, 0.0, {
            time: to_time
          });
          return false;
        }

        /*                            var transformMatrix = slider_inner.css("-webkit-transform") ||
                                        slider_inner.css("-moz-transform") ||
                                        slider_inner.css("-ms-transform") ||
                                        slider_inner.css("-o-transform") ||
                                        slider_inner.css("transform");
                                    var matrix = transformMatrix.replace(/[^0-9\-.,]/g, '').split(',');
                                    var translate_x = matrix[12] || matrix[4]; //translate x
                                    //var translate_y = matrix[13] || matrix[5]; //translate y*/

        var translate_x = $(slider_inner)[0]._gsTransform.xPercent;

        //console.log();
        var abs_translate_x = Math.abs(translate_x);
        //console.log(abs_translate_x);
        var slider_width = $(slider_inner).width();

        /////////////////
        var time_line_label, max_possible_abs_translate_x, take_one_slide_off_max_abs_translate_x;
        if ($(this).is(".SURGE_go_forward")) {
          if (current_label.indexOf("start") >= 0 || current_label.indexOf("start_inner_animation") >= 0 || current_label.indexOf("not_animating_just_showing_slide") >= 0) {
            from_label = "start_removing_slide" + current_label_number;
            last_plus_one = parseInt(current_label.match(/\d+/), 10) + 1;
            //console.log("last_plus_one: "+last_plus_one);
            //console.log("number_of_slides: "+number_of_slides);

            if (last_plus_one > number_of_slides && setting.animation === "fade") {
              tl.tweenFromTo("start", "not_animating_just_showing_slide1");
              //console.log("here1");
            } else if (last_plus_one > number_of_times_to_do_slide_animation && setting.animation === "slide") {
              max_possible_abs_translate_x = number_of_times_to_do_slide_animation * 100;
              take_one_slide_off_max_abs_translate_x = max_possible_abs_translate_x - 100;
              //console.log("here2");
              if (max_possible_abs_translate_x === abs_translate_x) { // its all the way to the end                                            
                // back to the start
                TweenMax.to(tl, 0.0, {
                  time: 0
                });
                //console.log("here3");
              } else {
                tl.tweenFromTo(from_label, purpusly_undefined);
                //console.log("here4");
              }
            } else if (setting.type === "grouped slider" && setting.animation === "fade" && last_plus_one > x) {
              tl.tweenFromTo("start", "not_animating_just_showing_slide1");
              //console.log("here5"); 
            } else {
              //console.log("here6");
              to_label = "not_animating_just_showing_slide" + last_plus_one;
              tl.tweenFromTo(from_label, to_label);
            }
          }
        }
        if ($(this).is(".SURGE_go_back")) {
          //if (current_label.indexOf("start") >= 0 || current_label.indexOf("start_inner_animation") >= 0 || current_label.indexOf("not_animating_just_showing_slide") >= 0) {
          from_label = "not_animating_just_showing_slide" + current_label_number;
          last_minus_one = parseInt(current_label.match(/\d+/), 10) - 1;

          //console.log("number_of_slides: "+number_of_slides);

          if (setting.animation === "slide") {
            if (current_label_number > 1 && current_label_number !== number_of_times_to_do_slide_animation) {
              // must be a middle slide
              tl.tweenFromTo("not_animating_just_showing_slide" + current_label_number, "not_animating_just_showing_slide" + last_minus_one, {}); // take it back to the last slide
            } else {
              if (typeof translate_x === "undefined") { // has not slide yet
                //console.log("1");
                tl.tweenFromTo(purpusly_undefined, purpusly_undefined, {});
              } else if (abs_translate_x === 0) { // has slide but has came back to first slide - so its now set
                //console.log("2");
                tl.tweenFromTo("start_removing_slide" + number_of_times_to_do_slide_animation, purpusly_undefined, {});
              } else if (abs_translate_x < 100) { // has started to slide to the second slide but has not yet got the full way
                //console.log("3");
                tl.tweenFromTo(tl.time(), 0, {}); // take it back to the start
              } else if (current_label_number === number_of_times_to_do_slide_animation) { // it could be the last or the second last slide
                //console.log("4");
                max_possible_abs_translate_x = number_of_times_to_do_slide_animation * 100;
                take_one_slide_off_max_abs_translate_x = max_possible_abs_translate_x - 100;

                if (max_possible_abs_translate_x === abs_translate_x) { // its all the way to the end
                  //console.log("5");
                  tl.tweenFromTo(tl.time(), "not_animating_just_showing_slide" + current_label_number, {}); // go back one but keep it on this end loop
                } else if (abs_translate_x < max_possible_abs_translate_x && abs_translate_x !== take_one_slide_off_max_abs_translate_x) { // its moving to wards the end but not yet all the way to it
                  //console.log("6");
                  tl.tweenFromTo(tl.time(), "not_animating_just_showing_slide" + current_label_number, {});
                } else if (abs_translate_x === take_one_slide_off_max_abs_translate_x) { // its waiting on the second last slide
                  //console.log("7");
                  tl.tweenFromTo("not_animating_just_showing_slide" + current_label_number, "not_animating_just_showing_slide" + last_minus_one, {}); // take it back to the last slide
                }
              }
            }
          } else { // is fade
            if (last_minus_one === 0) {
              tl.tweenFromTo("start" + x, purpusly_undefined);
            } else {
              to_label = "not_animating_just_showing_slide" + last_minus_one;
              if (time) {
                //console.log("time: " + time + " | to_label: " + to_label);
                tl.tweenFromTo(time, to_label, {});
              } else {
                //var from_label_time = tl.getLabelTime(from_label);
                var to_label_time = tl.getLabelTime(to_label);

                //console.log("from_label_time: " + from_label_time + " | to_label_time: " + to_label_time);

                // not using this because to sometimes failed to go all the way back.                                    
                //tl.tweenFromTo(from_label_time, to_label_time);
                /* or this */
                /*                                  var from_label_time = tl.getLabelTime(from_label);
                                                    var to_label_time = tl.getLabelTime(to_label);

                                                    var inner_last_from_label_time = tl.getLabelTime("start_inner_animation"+last_minus_one);
                                                    var length_of_start_inner_animation = from_label_time - inner_last_from_label_time;
                                                    var detuct_amount = length_of_start_inner_animation / 10;
                                                    detuct_amount = length_of_start_inner_animation - detuct_amount;
                                                    var final = inner_last_from_label_time + detuct_amount; 

                                                    console.log("from_label_time: " + final + " | to_label_time: " + to_label_time);
                                                    tl.tweenFromTo(final, to_label_time);
                                                    */
                TweenMax.to(tl, 0.0, {
                  time: to_label_time
                });
              }
            }
          }
        }
      });
    }
  };


  /* END GSAP image slider homepage */


  /* load images after page load */
  $(document).ready(function() {
    var do_images = function() {
      var setURL;
      $.each($(".SURGE_iamge_swap"), function() {
        current_URL = $(this).attr('src');
        if (Modernizr.mq('(max-width: ' + setup_calc_viewport.return_calculated_viewport(991) + 'px)')) {
          setURL = $(this).attr('data-mobile');
        } else {
          setURL = $(this).attr('data-desktop');
        }
        if (typeof current_URL === typeof undefined) {
          // must be fist go - is div - so convert it to an image
          $(this).replaceWith(function() {
            return $("<img>", {
              'class': this.className,
              src: setURL,
              alt: $(this).attr('alt'),
              'data-desktop': $(this).attr('data-desktop'),
              'data-mobile': $(this).attr('data-mobile')
            });
          });
        } else {
          if (current_URL !== setURL) {
            $(this).attr("src", setURL);
          }
        }
      });
    };
    $(window).on("resize", do_images);
    do_images();

    var do_slider_text_height = function() {
      if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(992) + 'px)')) {
        var slider_height = $("#SURGE_GSAP_slider1 .SURGE_iamge_swap").css("height");
        //console.log(slider_height);
        $(".top_content_container").css("height", slider_height);
      }
    };

    setTimeout(function() {
      do_slider_text_height();
    }, 200);

    // Call all functions again per resize
    $(window).resize(function() {
      do_slider_text_height();
    });

    var main_slider = new SURGE_GSAP_image_slider({
      slider: "#SURGE_GSAP_slider1",
      show_duration: 12,
      timeline: new TimelineMax({
        repeat: -1,
        repeatDelay: 0
      }),
      type: "main slider",
      slides_in_group: 1,
      pause_on_hover: true,
      animation: "fade", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })                       
      animation_duration: 0.8
    });

    var subpage_slider = new SURGE_GSAP_image_slider({
      slider: "#SURGE_GSAP_slider2",
      show_duration: 12,
      timeline: new TimelineMax({
        repeat: -1,
        repeatDelay: 0
      }),
      type: "main slider",
      slides_in_group: 1,
      pause_on_hover: true,
      animation: "fade", // if you set this to "slide" animation then please also set up TimelineMax with TimelineMax({ yoyo: true, repeatDelay: what ever the show_duration was, })                       
      animation_duration: 0.8
    });

  });
  /* END load images after page load */

  // center things
  TweenMax.set(".center_me", {
    xPercent: -50,
    yPercent: -50
  });

  // Center home page image

  var top_image_overlay_contents = function() {
    if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(992) + 'px)')) {
      TweenMax.set(".top_home_section_overlay", {
        yPercent: -50
      });
    } else {
      TweenMax.set(".top_home_section_overlay", {
        clearProps: "transform"
      });
    }
  };

  // Center play button on video overlay
  TweenMax.set(".placeholder_overlay", {
    xPercent: -50,
    yPercent: -50
  });

  top_image_overlay_contents();

  if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(992) + 'px)')) {
    $(".top_content_container").css("min-height", $(".SURGE_gsap_inner .slide_image").outerHeight(true));
  }

  $(window).resize(function() {
    top_image_overlay_contents();
    if (Modernizr.mq('(min-width: ' + setup_calc_viewport.return_calculated_viewport(992) + 'px)')) {
      $(".top_content_container").css("min-height", $(".SURGE_gsap_inner .slide_image").outerHeight(true));
    }
  });

})(jQuery); // Fully reference jQuery after this point.
