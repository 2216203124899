var setup_calc_viewport = (function($) {
    var original_window_width = $(window).width();
    /*    if (navigator.userAgent.match(/Mobi/)) {           
            if (window.innerHeight > window.innerWidth) { // is portrait
                if ($(window).width() <= 320) { // iphone 5
                    $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.74, minimum-scale=0.74");
                } else if ($(window).width() <= 360) { // gal s5
                    $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.84, minimum-scale=0.84");
                } else if ($(window).width() <= 375) { // iphone 6
                    $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.87, minimum-scale=0.87");
                }
            } else { // is landscape
                if ($(window).width() <= 568) { // iphone 5
                    $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.74, minimum-scale=0.74");
                } else if ($(window).width() <= 640) { // gal s5
                    $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.84, minimum-scale=0.84");
                } else if ($(window).width() <= 667) { // iphone 6
                    $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=0.87, minimum-scale=0.87");
                }
            }
        }*/

    var added_viewpot_width = $(window).width() - original_window_width,
        return_calculated_viewport = function(requested_width) {
            return requested_width - added_viewpot_width;
        };



    // on first load
    if ($(window).width() < 500) {
        //$("meta[name='viewport']").attr("content", "width=500");
        var dif = 500 - $(window).width(),
            one_percent = 500 / 100,
            percentage_to_change_by = dif / one_percent,
            percentage_to_decimal = percentage_to_change_by / 100,
            final_updated_viewport_scale = 1 - percentage_to_decimal;
            $("meta[name='viewport']").attr("content", "width=500, initial-scale=" + final_updated_viewport_scale + ", minimum-scale=" + final_updated_viewport_scale);
    }



        // resise
        var protection_width = $(window).width(),
/*            block_if_higher_width_next = false,
            block_if_lower_width_next = false,*/
            changed_viewport_width = function(requested_width) {
                return $(window).width() - protection_width;
            },
            min_enforced_width_for_mobile = 500,
            //skip_next_resize_event = false,       
            update_viewport = function() {
                console.log("update_viewport");
    /*            if (skip_next_resize_event === true) {
                    skip_next_resize_event = false;
                    return false;
                }*/
                //console.log("here 1 ");
                var triggered_resize_with = $(window).width();

/*                if (protection_width > triggered_resize_with && block_if_lower_width_next) {
                    block_if_lower_width_next = false;
                    return false;
                }
                if (triggered_resize_with > protection_width && block_if_higher_width_next) {
                    block_if_higher_width_next = false;
                    return false;
                }
                ///////
                if (protection_width > triggered_resize_with) {
                    change_since_last = protection_width - triggered_resize_with;
                    //re_calculated_protection_width = protection_width + dif_since_last;
                    block_if_higher_width_next = true;
                } else {
                    change_since_last = triggered_resize_with - protection_width;
                    block_if_lower_width_next = true;
                    //re_calculated_protection_width = protection_width - dif_since_last;
                }*/


                if (protection_width !== triggered_resize_with) {
                    protection_width = triggered_resize_with;
/*                    console.log("triggered_resize_with: " + triggered_resize_with);
                    console.log("protection_width: " + protection_width);
                    console.log("change_since_last: " + change_since_last);*/

                    if (triggered_resize_with < 500) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening

                        //if (triggered_resize_with > ) {}                    
                        //console.log("here 2");
                        var dif = min_enforced_width_for_mobile - protection_width,
                            one_percent = min_enforced_width_for_mobile / 100,
                            percentage_to_change_by = dif / one_percent,
                            percentage_to_decimal = percentage_to_change_by / 100,
                            final_updated_viewport_scale = 1 - percentage_to_decimal;
                        //skip_next_resize_event = true;

/*                        console.log("----");
                        console.log(protection_width);
                        //console.log(changed_viewport_width($(window).width()));
                        console.log("----");*/

                        $("meta[name='viewport']").attr("content", "width=500, initial-scale=" + final_updated_viewport_scale + ", minimum-scale=" + final_updated_viewport_scale);
                    } else {
                        console.log("here 3");
                        $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=1");
                    }
                    $(window).off("resize.update_viewport");
                    setTimeout(function() {
                        $(window).on("resize.update_viewport", update_viewport);
                    }, 800);
                }
            };
        $(window).on("resize.update_viewport", update_viewport);
        //update_viewport();






    /*    var protection_width = $(window).width(),
            block_if_higher_width_next = false,
            block_if_lower_width_next = false,
            changed_viewport_width = function(requested_width) {
                return $(window).width() - protection_width;
            },
            min_enforced_width_for_mobile = 500,
            //skip_next_resize_event = false,       
            update_viewport = function() {
                console.log("update_viewport");*/
    /*            if (skip_next_resize_event === true) {
                    skip_next_resize_event = false;
                    return false;
                }*/
    /*            console.log("here 1 ");
                var triggered_resize_with = $(window).width();

                if (protection_width > triggered_resize_with && block_if_lower_width_next) {
                    block_if_lower_width_next = false;
                    return false;
                }
                if (triggered_resize_with > protection_width && block_if_higher_width_next) {
                    block_if_higher_width_next = false;
                    return false;
                }
                ///////
                if (protection_width > triggered_resize_with) {
                    change_since_last = protection_width - triggered_resize_with;
                    //re_calculated_protection_width = protection_width + dif_since_last;
                    block_if_higher_width_next = true;
                } else {
                    change_since_last = triggered_resize_with - protection_width;
                    block_if_lower_width_next = true;
                    //re_calculated_protection_width = protection_width - dif_since_last;
                }


                if (protection_width !== triggered_resize_with) {
                    protection_width = triggered_resize_with;
                    console.log("triggered_resize_with: " + triggered_resize_with);
                    console.log("protection_width: " + protection_width);
                    console.log("change_since_last: " + change_since_last);

                    if (triggered_resize_with < 500) { // resize was being fired on scroll on chrome mobile browser (address bar moving down) - here we are protecting out code from this happening

                        //if (triggered_resize_with > ) {}                    
                        console.log("here 2");
                        var dif = min_enforced_width_for_mobile - protection_width,
                            one_percent = min_enforced_width_for_mobile / 100,
                            percentage_to_change_by = dif / one_percent,
                            percentage_to_decimal = percentage_to_change_by / 100,
                            final_updated_viewport_scale = 1 - percentage_to_decimal;
                        //skip_next_resize_event = true;

                        console.log("----");
                        console.log(protection_width);
                        console.log(changed_viewport_width($(window).width()));
                        console.log("----");

                        $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=" + final_updated_viewport_scale + ", minimum-scale=" + final_updated_viewport_scale);
                    } else {
                        console.log("here 3");
                        $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=1");
                    }
                    $(window).off("resize.update_viewport");
                    setTimeout(function() {
                        $(window).on("resize.update_viewport", update_viewport);
                    }, 800);
                }
            };
        $(window).on("resize.update_viewport", update_viewport);
        update_viewport();*/


    this.return_calculated_viewport = return_calculated_viewport;
    //console.log(added_viewpot_width);
    return this;
})(jQuery); // Fully reference jQuery after this point.

(function($) {
    /*    console.log(setup_calc_viewport.return_calculated_viewport(375) + "|" + $(window).width());

        var testing_this = function() {
            if ($(window).width() == setup_calc_viewport.return_calculated_viewport(375)) {
                alert("match");
            }
            console.log(setup_calc_viewport.return_calculated_viewport(375));
        };
        $(window).resize(function() {

            testing_this();
        });
        testing_this();

    */
})(jQuery); // Fully reference jQuery after this point.

/*
called this code with
setup_calc_viewport.return_calculated_viewport();
*/
